<ng-template #content let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                                Datos del Perfil
                                <span *ngIf="view == 2" class="text-dark"> (Direcciones)</span>
                                <span *ngIf="view == 3" class="text-dark"> (Condición Laboral)</span>
                                <span *ngIf="view == 4" class="text-dark"> (Ingresos Mensuales)</span>
                                <span *ngIf="view == 5" class="text-dark"> (Medios de pago)</span>
                                <span *ngIf="view == 6" class="text-dark"> (Editar Medio de pago)</span>
                                <span *ngIf="view == 7" class="text-dark"> (Datos basicos)</span>


                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)=" view > 1 ? view !== 6?view = 1: view = 5 : offcanvas.dismiss('Cross click'); "></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12 col-md-6 offset-md-3">

                    <app-data-profile  [estatus_approve]="analysis_response" [person]="person" [code_person]="person?.person?.code" *ngIf="view == 1" (resetStatus)="setResetStatus($event)" (changeView)="setChangView($event)"></app-data-profile>
                    <app-payment-method   [estatus_approve]="analysis_response"  [code_person]="person?.person?.code" *ngIf="view == 5" (resetStatus)="setResetStatus($event)" (editView)="editPaymentMethod($event)" (changeView)="setChangView($event)"></app-payment-method>
                    <app-address  [estatus_approve]="analysis_response"  [code_person]="person?.person?.code" *ngIf="view == 2" (resetStatus)="setResetStatus($event)" (changeView)="setChangView($event)"></app-address>
                    <app-working-condition [code_person]="person?.person?.code" *ngIf="view == 3" (resetStatus)="setResetStatus($event)" (changeView)="setChangView($event)"></app-working-condition>
                    <app-income   [code_person]="person?.person?.code" *ngIf="view == 4" (resetStatus)="setResetStatus($event)" (changeView)="setChangView($event)"></app-income>
                    <app-payment-method-edit *ngIf="view == 6" [dataEdit]="current_payment_method_id" (resetStatus)="setResetStatus($event)" (changeView)="setChangView($event)"></app-payment-method-edit>
                    <app-edit-data-base   [estatus_approve]="analysis_response"  [person]="person" [code_person]="person?.person?.code" *ngIf="view == 7" (resetStatus)="setResetStatus($event)" (editView)="editBaseData($event)" (changeView)="setChangViewPerson($event)"></app-edit-data-base>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #content2 let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                                Detalles de aprobación
                        
                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)=" view > 1 ? view !== 6?view = 1: view = 5 : offcanvas.dismiss('Cross click'); "></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12 col-md-6 offset-md-3">

                    <div *ngFor="let item of approve_type_person" class="mt-4">

                        <div>
                            <div class="text-success float-end" > {{item?.approve_status}}</div>

                            <div class="fw-bold" > {{item?.approve_type?.name}}</div>

                        </div>

                        <div class="small">

                            <div class="" > {{item?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                        </div>

                        <div class="mt-1 small text-secondary" *ngIf="item?.approve_type?.name  == 'BURO'">
                            Puntaje :
                            <span *ngIf="item.response?.puntaje"> {{item.response?.puntaje}} </span>
                            <span *ngIf="item.response?.Informe?.Score?.dolar?.puntaje"> {{item.response?.Informe?.Score?.dolar?.puntaje}} </span>


                           
                           

                        </div>

                    <!--    <pre>{{ item.response | json }}</pre>  -->

                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</ng-template>




<div class="mt-md-3 p-3">

    <div class="row ">
        <div class=" col-12 col-md-8 offset-md-2">


            <h5 class="mb-1 text-secondary  mb-4 text-center"> <i class="  fas fa-user"></i> Onboarding
            </h5>
            


            <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && !person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                <img *ngIf="person?.person.gender != 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatar.png')">
                <img *ngIf="person?.person.gender == 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatara-female.png')">

            </div>
            

            <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                <img class="rounded-circle  " [src]="sanitize(imgPerson)">
            </div>


            
            <div *ngIf="!loading && person?.person" class="text-center mb-5">
                <div class="text-capitalize h4 text-dark">{{person?.person?.name}} {{person?.person?.lastName}}</div>
                <div> {{getIdentity(person)}}</div>
                <div> {{getContact(person)}}</div>
                <div> {{getEmailContact(person)}}</div>

          
                <div class="pt-2" *ngIf="person?.person?.direct_debit_afiliation"><span class="fas fa-check-circle text-success  fz-20"></span> {{ 'Domiciliado ' }} <b><span class="font-weight-bold">{{ person?.person?.direct_debit_afiliation?.bank?.name }}</span></b></div>

                <!-- <div class="pt-2" *ngIf="domiciliation_current?.bank?.name"><span class="fas fa-check-circle text-success  fz-20"></span> {{ 'Domiciliado ' }} <span class="font-weight-bold">{{ domiciliation_current?.bank?.name }}</span></div> -->
            </div>


            <ng-container *ngIf="!loading && person?.person" class="mt-2">

                <div class=" mt-4 list-group list-group-radio d-grid gap-2 border-0 w-auto" *ngIf="section_transaction">
                    <div *ngFor="let item of section_transactions" class="position-relative">

                        <label *ngIf=" (item?.code != 'SELFIE')  &&   (item?.code != 'PHOTO_IDENTITY') && (item.conditionSection == 'COMPLETE' || item.visibility == true ||  item?.code == 'PROFILE_DATA' || status_approved) " class="list-group-item py-3 " for="listGroupRadioGrid1">

                            <ng-container >
                                <ng-container  *ngIf="section_transaction['TERMS_AND_CONDITIONS']?.condition ==  'COMPLETE' || item?.code == 'PRE_REGISTER' ">
                                    

                                    <span *ngIf="(item?.code == 'PROFILE_DATA' && item?.visibility == true && section_transaction[item?.code]?.condition ==  'COMPLETE') || (item?.code == 'BURO' && !loading_buro && section_transaction[item?.code]?.condition ==  'COMPLETE') || (item?.code != 'PROFILE_DATA' && item?.code != 'BURO' && section_transaction[item?.code]?.condition ==  'COMPLETE')" class="fas fa-check-circle text-success float-end fz-25"></span>
                                    
                                     <span *ngIf="section_transaction[item?.code]?.condition !=  'COMPLETE' && item?.code == 'BURO' && !loading_buro" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="getDataBuro(person)">Consultar</button>
                                    </span>

                                    <span *ngIf="loading_buro && item?.code == 'BURO'" class="small text-dark float-end">Cargando...</span>

                                    <span *ngIf="section_transaction[item?.code]?.condition !=  'COMPLETE' && item?.code == 'PHOTO_IDENTITY'" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="setBiometria('PHOTO_IDENTITY')">Completar</button>
                                    </span>

                                    <div *ngIf="item?.visibility == false  && item?.code == 'PROFILE_DATA'" class="small text-secondary float-end">
                                       
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="pocisionBottom(content)">Completar</button>
                                        
                                    </div>

                                    <div  *ngIf="section_transaction[item?.code]?.condition ==  'COMPLETE' && item?.visibility == true && item?.code == 'PROFILE_DATA'" class=" text-primary float-end me-2">
                                        
                                        <span (click)="pocisionBottom(content)"  class="text-secondary" > Editar</span>

                                    </div>

                                    <span *ngIf="section_transaction[item?.code]?.condition !=  'COMPLETE' &&  item?.code == 'SELFIE'" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="setBiometria('SELFIE')">
                                            <span > Completar</span>

                                        </button>
                                        
                                    </span>

                                
                                    <div   *ngIf="section_transaction[item?.code]?.condition ==  'COMPLETE'  &&  item?.code == 'SELFIE'"  class=" text-primary float-end me-2">

                                        <span (click)="setBiometria('SELFIE')" class="text-secondary" > Editar</span>

                                    </div>    

                                    <div *ngIf="section_transaction[item?.code]?.condition ==  'COMPLETE'  &&  item?.code == 'SELFIE'"  class=" text-primary float-end me-2">
                                        <span (click)="redirectIMG(person?.person?.file_selfie?.url)">Ver </span>

                                    </div>


                                    <div   *ngIf="section_transaction[item?.code]?.condition ==  'COMPLETE'  &&  item?.code == 'PHOTO_IDENTITY'"  class=" text-primary float-end me-2">

                                        <span (click)="setBiometria('PHOTO_IDENTITY')" class="text-secondary" > Editar</span>

                                    </div>    


                                    <div *ngIf="section_transaction[item?.code]?.condition ==  'COMPLETE' && item?.code == 'PHOTO_IDENTITY'" class=" text-primary float-end me-2">
                                        <span (click)="redirectIMG(person?.person?.file_identity?.url)">Ver </span>

                                    </div>


                                    <!--
                                    <span *ngIf="item?.code == 'ANALYSIS'  && !loading_analysis && analysis_response " class="fas fa-check-circle text-success float-end fz-25"></span>
                                    
                                    <span *ngIf="item?.code == 'ANALYSIS'  && section_transaction[item?.code]?.condition != 'COMPLETE' && !loading_analysis && !analysis_response" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="setAnalysis(person)">Consultar</button>
                                    </span>

                                    <span *ngIf="loading_analysis && item?.code == 'ANALYSIS'" class="small text-dark float-end">Cargando...</span>
                                 -->
                              
                                </ng-container> 

                                <ng-container  *ngIf="section_transaction['TERMS_AND_CONDITIONS']?.condition !=  'COMPLETE' && section_transaction[item?.code]?.condition !=  'COMPLETE'">
                                    <span  class="small  float-end text-secondary" >Pendiente</span>
                                </ng-container>

                            </ng-container>
                            

                            <ng-container  *ngIf="section_transaction['TERMS_AND_CONDITIONS']?.condition ==  'COMPLETE'" ></ng-container>

                                   <div class="mb-2"><strong class="fw-semibold">{{item.name}}</strong></div> 
                                    <span class="d-block small opacity-75">{{item.description}}</span>
                                    <span *ngIf="!loading_buro && item?.code == 'BURO' && buro" class="small text-dark mt-1">
                                     Fecha   <span class="fw-bold">{{buro.fechaConsulta | date: 'dd/MM/yyyy h:mm:ss a'}} </span> 
                                      <!-- <span *ngIf="buro_score"> / Score </span>
                                      <span class="fw-bold"  *ngIf="buro_score">{{buro_score}}</span> -->
                                    </span>

                                    <span *ngIf="item?.code == 'TERMS_AND_CONDITIONS' && person?.person?.date_response_terms_and_conditions " class="small text-dark mt-1">
                                        Fecha de aprobación  <span class="fw-bold">  {{person?.person?.date_response_terms_and_conditions | date: 'dd/MM/yyyy'}}</span> 
                                         <!-- <span *ngIf="buro_score"> / Score </span>
                                         <span class="fw-bold"  *ngIf="buro_score">{{buro_score}}</span> -->
                                    </span>
                                    
                                    <div *ngIf="item?.code == 'ANALYSIS'  && !loading_analysis && analysis_response ">
                                        <div class="mt-3" >

                                        <div   class=" text-primary float-end me-2"  (click)="pocisionBottom(content2)">
                                            <span  >Ver </span>
    
                                        </div>

                                            <span class="badge rounded-pill bg-secondary me-2 mt-2">Fecha : {{analysis_response?.date | date: 'dd/MM/yyyy h:mm:ss a' }}</span>
                                            <span class="badge rounded-pill bg-secondary me-2 mt-2">Estatus : {{analysis_response?.showStatusValue}}</span>
                                            <span class="badge rounded-pill bg-secondary me-2 mt-2">Respuesta : {{analysis_response?.showStatusApprove}}</span>
                                            <span  class="badge rounded-pill bg-secondary me-2 mt-2" *ngIf="_auth.IsPermitid(['OWNER'])" >Consultado en : 

                                                <span *ngFor="let item of analysis_response?.approve_type_history;let i=index" >
                                                    
                                                    <span *ngIf="i > 0">, </span>
                                                    {{item}}
                                                </span>
                                            
                                            </span>

                                        </div>

                                    </div>
                         </label>

                         
                    </div>
                    
                    
                  
                       
            <!--<pre>{{ analysis_response | json }}</pre>--> 
             
                    <div  *ngIf="status_approved == 'BURO_PSICOMETRICO'" class="position-relative">

                        <div class="list-group-item py-3 " for="listGroupRadioGrid1">
                            <div class=" text-secondary float-end text-end">

                                <ng-container *ngIf="start_psicometrico" >

                                    <button type="button" *ngIf="activatePsico " class=" btn btn-secondary btn-sm" (click)="setStatus_psicometrico(false)">Desactivar psicométrico </button>
                                    <button type="button" *ngIf="!activatePsico " class=" btn btn-primary btn-sm" (click)="setStatus_psicometrico(true)">Activar psicométrico </button>

                                </ng-container>
                          
                                <!-- 
                                <ng-container *ngIf="!start_psicometrico_load && status_approved == 'WAIT_PSICOMETRICO' && buro.start_psicometrico && !start_psicometrico">

                                    <div class="text-warning small"> En espera de resultado </div>
                                    <div class="text-secondary small">Activado {{buro.consult_psicometrico_date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                </ng-container>
                            -->

                            </div>
                            <div>
                                <strong class="fw-bold" >Psicometrico</strong>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="status_approved == 'APPROVED'" class="position-relative mt-2">

                        <label style="    background: #FEFEFE; border: var(--bs-success) 1px solid;" class="list-group-item py-3 " for="listGroupRadioGrid1">
                            <span class=" text-secondary float-end">

                                <span  class=" text-dark fw-bold">{{person?.person?.quota | currency:'USD':'': '1.2-2'}} USD</span>
                            </span>
                            <strong class="fw-semibold">Cupo disponible</strong>
                            <br>

                            <span *ngIf="person?.person?.quota_active" class=" small text-success">Activo </span>
                            <span *ngIf="!person?.person?.quota_active" class=" small text-danger">Inactivo  </span>

                         </label>

                    </div>


                </div>


            </ng-container>


            <div *ngIf="!loading && !person?.person">
                <div>No se encontro person con este id: {{queryParams?.code}}</div>
            </div>

            <ng-container *ngIf="loading ">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>

            <div *ngIf="loading_buro " class="text-center mt-2">
                <div>Consultando a buró...</div>
            </div>

        </div>
    </div>

</div>