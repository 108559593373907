import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as alertifyjs from 'alertifyjs';
import Swal from 'sweetalert2';
import { DirectDebitService } from '../../services/direct-debit/direct-debit.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
@Component({
  selector: 'app-dashboard-direct-debit-affiliation',
  templateUrl: './dashboard-direct-debit-affiliation.component.html',
  styleUrls: ['./dashboard-direct-debit-affiliation.component.css'],
  providers: [DatePipe]
})
export class DashboardDirectDebitAffiliationComponent implements OnInit {

  startTraceDocument = true;
  startTraceDocumentDomiciliation = true;
  file: any;
  loading = true;
  sessionUser: any;
  profiles: any;
  profile: any;
  current_direct_debit: any;
  status_sections: any = [];
  current_status_sections: any = [];
  loading_section: any = [];
  show_conciliacion_button = false;
  obj_conciliacion: any;
  provider: any = {
    tax_id_letter: 'J',
    tax_id: '311501878',
    tax_id_verification: '0',
  };
  template: string = '/lotes/bdv/send_final';
  sections: any = [
    {
      id: 1,
      name: 'Generar archivo',
      status: 'INITIAL',
    },
    {
      id: 2,
      name: 'Descargar TXT',
      status: 'GENERATED',
    },
    {
      id: 3,
      name: 'Descargado',
      status: 'DOWNLOADED',
    },
    {
      id: 4,
      name: 'Archivo cargado al banco',
      status: 'UPLOADED',
    },
    {
      id: 5,
      name: 'Procesar Respuesta del banco',
      status: 'PROCESING',
    },
    {
      id: 6,
      name: 'Finalizado',
      status: 'FINISHED',
    },
  ];

  types: any = [
    {
      id: 'ALL',
      name: 'General',
    },
    {
      id: 'AFILIATION',
      name: 'Afiliación',
    },
    {
      id: 'DESAFILIATION',
      name: 'Desafiliación',
    }
   
  ];

  banks: any = [];

  pending: any;

  view = 1;
  form: FormGroup | any = this.formBuilder.group({
    bank: [''],
    type: [''],
  });
  files: any = [];

  date: any = new Date();
  current_date: any = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  btn_restart = false;
  btn_restart_conciliation = false;
  response_trace_conciliation: any;

  config: any; //configuración del template que genera el formato de domiciliación (afiliación)
  config_read: any;

  constructor(
    private userService: UserService,
    private offcanvasService: NgbOffcanvas,
    private formBuilder: FormBuilder,
    private directDebitService: DirectDebitService,
    public _auth: AuthService,
    private datePipe: DatePipe
  ) {}

  async ngOnInit() {
    this.sessionUser = this._auth.sessionUser;
    this.profiles = this._auth.getProfiles();
    this.profile = this.getProfile(this.sessionUser);
    this.banks = await this.getBanks();
    this.banks = this.banks.res;
    this.banks = this.banks.filter((item:any) => item.direct_debit_afiliation_required);
    this.setDefault();
    this.updateCurrentDirectDebit();
    this.config = await this.getDirectDebitConfig('template');
    this.config_read = await this.getDirectDebitConfig('read');
  }

  getCurrentDate = async () => {
    this.pending = await this.directDebitService.pendingLoteAfiliation();
    if (this.pending.resp.length > 0) {
      this.date = this.datePipe.transform(
        this.pending.resp[0].date,
        'yyyy-MM-dd'
      );
    } else {
      this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
  };

  getProfile(user: any) {
    let profiles = user?.profile;
    let profile;
    if (profiles) {
      let value = this.profiles[profiles[0]];
      let key = profiles[0];
      profile = { key, value };
    }
    return profile;
  }

  updateStatusSections = async () => {
    await this.getStatusCurrentDirectDebit();
    let iter_section;
    this.sections.map((section: any) => {
      this.status_sections[section.status] = 'PENDING';
      iter_section = this.validateSection(section.status);
      if (iter_section) {
        this.status_sections[section.status] = iter_section;
      }
    });
    this.config = await this.getDirectDebitConfig('template');
    this.loading = false;
  };


  validateSection = (status: string) => {
    return this.current_status_sections.find((section: any) => {
      return section.status === status;
    });
  };



  // updateCurrentDirectDebit = async (direct_debit: any) => {
  //   this.getCurrentDate();
  //   //this.current_direct_debit = null;
  //   this.files = [];
  //   if(direct_debit) {
  //     this.current_direct_debit = direct_debit;

  //   } else {
  //     this.current_direct_debit = null;
  //   }
  //   this.updateStatusSections();
  // };

  getConfig = () => {

  }

  updateCurrentDirectDebit = async () => {
    this.getCurrentDate();
    //this.current_direct_debit = null;
    this.files = [];
debugger;
    this.current_direct_debit = await this.getDebitDirectByBankAndType(
      this.form.getRawValue().bank,
      this.form.getRawValue().type,
      this.date
    );
    debugger;
    if (this.current_direct_debit) {
      if (this.current_direct_debit.res.length > 0) {
        this.current_direct_debit = this.current_direct_debit.res[0];
      } else {
        this.current_direct_debit = null;
      }
    } else {
      this.current_direct_debit = null;
    }
    this.updateStatusSections();
    this.startTraceDocument =false;
    setTimeout(()=> { this.startTraceDocument = true }, 5);

    this.startTraceDocumentDomiciliation =false;
    setTimeout(()=> { this.startTraceDocumentDomiciliation = true }, 5);

  }

  setResponse = (elements: any[], type: string) => {
    console.log('Esta es la respuesta');
    console.log(elements);
    if(elements.length>0) {
      if(elements[0].state==="ERROR" && type==="START") {
        this.btn_restart = true;
      }else if(elements[0].state==="ERROR" && type==="CONCILIATION") {
        this.btn_restart_conciliation = true;
        this.response_trace_conciliation = elements;
      } else if(type==="CONCILIATION") {
        this.response_trace_conciliation = elements;
      }
    }
  }


  getStatusCurrentDirectDebit = async () => {
    if (this.current_direct_debit) {
      this.current_status_sections = await this.getDebitDirectAfiliationStatuses(
        this.current_direct_debit._id
      );
      this.current_status_sections = this.current_status_sections.res;
    } else {
      this.current_status_sections = [];
    }
  };

  setDefault = () => {
    if (this.banks.length === 1) {
      this.form.get('bank').setValue(this.banks[0].code);
      this.form.get('bank').patchValue(this.banks[0].code);
    }
    if (this.types.length >= 1) {
      this.form.get('type').setValue(this.types[0].id);
      this.form.get('type').patchValue(this.types[0].id);
    }
  };

  getDebitDirectByBankAndType = (
    bank: string,
    type: string,
    date: any
  ): Promise<any> => {
    debugger;
    return this.directDebitService.getDirectDebitAfiliation(bank, type, date);
  };

  getDirectDebitConfig = (type: string): Promise<any> => {
    let bank = this.form.getRawValue().bank;
    return this.directDebitService.getDirectDebitConfig(bank, type, 'AFILIATION');
  };


  getDebitDirectStatuses = (direct_debit_id: string): Promise<any> => {
    return this.directDebitService.getDirectDebitStatuses(direct_debit_id);
  };

  getDebitDirectAfiliationStatuses = (direct_debit_id: string): Promise<any> => {
    return this.directDebitService.getDirectDebitAfiliationStatuses(direct_debit_id);
  };


  getBanks = async () => {
    return await this.userService.getBanksWithDirectDebitEnabled({});
  };

  openModalBanks = (content: TemplateRef<any>, data: any = {}) => {
    try {
      this.offcanvasService.open(content, {
        position: 'bottom',
        keyboard: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  pendingDirectDebit = async () => {
    return await this.directDebitService.pendingLoteAfiliation();
  };

  

  startDirectDebit = async () => {
      this.loading_section['INITIAL'] = true;
      let body = {
        type: this.form.getRawValue().type,
        bankCode: this.form.getRawValue().bank,
        user: this.sessionUser.uid,
        template: this.config.template,
        provider: this.provider,
      };
      debugger;
      try {
        let response = await this.directDebitService.startDirectDebitAfiliationProcess(body);
        // este es el único servicio que genera todo el proceso de domiciliación, en file llega el url del archivo generado
        //let response = await this.directDebitService.startDirectDebitProcess(body);
        if (response.success) {
          if (response.data) {
            await this.updateCurrentDirectDebit();
            delete this.loading_section['INITIAL'];
          } else {
            alertifyjs.error(
              'No hay pagos pendientes que cumplan con los críterios seleccionados'
            );
            delete this.loading_section['INITIAL'];
          }
        } else {
          alertifyjs.error(
            response.description
          );
          delete this.loading_section['INITIAL'];
        }
        delete this.loading_section['INITIAL'];
      } catch(error) {
          console.log(error);
          delete this.loading_section['INITIAL'];
          alertifyjs.error(
                error
          );
      }
    
  };

  downloadCheck = async () => {
    if(this.status_sections['FINISHED']==='PENDING' && this.status_sections['PROCESING']==='PENDING' && this.status_sections['UPLOADED']==='PENDING'){
      let body = {
        direct_debit_id: this.current_direct_debit._id,
        status: 'DOWNLOADED',
        user: this.sessionUser.uid,
      };
      console.log(body);
      await this.directDebitService.saveDirectDebitAfiliationStatus(body);
      await this.updateCurrentDirectDebit();
    }
  };

  offcanvasServiceClose() {
    this.view = 1;
    this.offcanvasService.dismiss();
  }

  reset = (evt: any) => {
    this.show_conciliacion_button = false;
    this.obj_conciliacion = null;
  };

  getResponse = (response: any) => {
    console.log(response);
    if (response) {
      this.show_conciliacion_button = true;
      this.obj_conciliacion = response.response;
      this.file = response.file;
    } else {
      this.show_conciliacion_button = false;
      this.obj_conciliacion = null;
      this.file = null;
    }
  };

  conciliar = async () => {
    console.log(this.obj_conciliacion);
    if (this.current_direct_debit && !this.loading_section['PROCESING']) {
      // este el el loading del botón
      this.loading_section['PROCESING'] = true;

      // lectura del header de la respuesta
      let header = this.getHeader(this.obj_conciliacion.data, 'MERCANTIL');

      // objeto requerido por el servicio de conciliación
      let obj = {
        bank_acron: "MERCANTIL",
        config: this.config_read.config,
        bank: this.current_direct_debit.bank,
        tax_id: this.provider.tax_id_letter + this.provider.tax_id,
        id_direct_debit: this.current_direct_debit._id,
        user: this.sessionUser.uid,
        url: this.obj_conciliacion.file.url,
        codigo: header[0]['CODRESLOTE'],
        trace: this.current_direct_debit.trace
      };
      debugger;
      try {
        try{
          // este es el único servicio encargado de la conciliación Juan
          await this.directDebitService.run_afiliations(JSON.stringify(obj), this.file);

          
          // estos servicios se utilizan para actualizar los estatus de las vistas, segun la tabla de direct debit statuses
          await this.updateCurrentDirectDebit();

          // se desmarca el proceso de conciliación activo
          this.show_conciliacion_button = false;
          this.loading_section['PROCESING'] = false;

        } catch(e) {
          alertifyjs.error(
            'Ha ocurrido un error en el proceso de conciliación, contacte con el administrador o reintente conciliar.' + e
          );
          this.show_conciliacion_button = true;
          this.loading_section['PROCESING'] = false;
        }
      } catch (error) {
        console.log(error);
        this.show_conciliacion_button = true;
        this.loading_section['PROCESING'] = false;
        alertifyjs.error(
          'Ha ocurrido un error en el proceso de conciliación, contacte con el administrador o reintente conciliar.' + error
        );
      }
    }
  };

 
  formatDataToExcel = (obj: any) => {
    let response: any = [];

    obj.map((element: any) => {
      console.log(element);
      response = [
        ...response,
        {
          CEDULA: element.identity,
          CREDITO: element.num_credit,
          REFERENCIA: element.reference,
          'NUMERO DE CUENTA': element.num_cuenta,
          'PROCESADO POR EL BANCO': element.success ? 'SI' : 'NO',
          DESCRIPCION: element.description,
          CONCILIADO: element?.conciliation?.reconciled_payment ? 'SI' : 'NO',
          MONTO: element.conciliation
            ? this.toFixedApp(
                element.conciliation.amount * element.conciliation.rate
              )
            : 0,
        },
      ];
    });
    return response;
  };

  toFixedApp = (x: any) => {
    let a: any = `e+${2}`;
    let b: any = `e-${2}`;
    return +(Math.round(x + a) + b);
  };

  findConciliationObject = (obj: any, reference: any) => {
    return obj.find((element: any) => element.paymentMethodId === reference);
  };

  getElements = (obj: any, type: any) => {
    let response: any = [];
    obj
      .filter((row: any) => row.TIPOREG !== '01')
      .map((row: any) => {
        if (row.TIPOREG === '02') response = [...response, row];
        if (row.TIPOREG === '03') {
          let position = response.findIndex((ele: any) => {
            return ele.REFDEB === row.REFDEB && !ele.ERROR;
          });
          if (position != -1) {
            console.log(response[position]['TOTCOB']);
            if(response[position]['TOTCOB']==="0.00"){
              response[position]['ERROR'] = row;
            }
          }
        }
      });
    return response;
  };

  confirm_upload = async () => {
    Swal.fire({
      title: 'Confirmación',
      text: 'Solo debe confirmar cuando el archivo se haya recibido de forma exitosa por el banco ¿Desea confirmar?',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: `Si!`,
      cancelButtonText: `Cancelar!`,
      showLoaderOnConfirm: true,

      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.loading_section['UPLOADED'] = true;
        let body_2 = {
          direct_debit_id: this.current_direct_debit._id,
          status: 'UPLOADED',
          user: this.sessionUser.uid,
          url: undefined,
        };
        await this.directDebitService.saveDirectDebitAfiliationStatus(body_2);
        this.loading_section['UPLOADED'] = false;
        await this.updateCurrentDirectDebit();
      }
    });
  };

  cancel_process = () => {
    Swal.fire({
      title: 'Alerta',
      text: '¿Desea cancelar el proceso, esta acción no se puede revertir, desea continuar?',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: `Si!`,
      cancelButtonText: `Cancelar!`,
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.loading_section['UPLOADED'] = true;
        let body = {
          id: this.current_direct_debit._id,
          user: this.sessionUser.uid,
        };
        await this.directDebitService.cancel_direct_debit_afiliacion(body);
        this.loading_section['UPLOADED'] = false;
        await this.updateCurrentDirectDebit();
      }
    });
  };

  restart_process_conciliation = async() => {
    let body = {
      trace: "DIRECTDEBIT_GENERATE_AFILIATIONS_"+this.current_direct_debit.trace
    };
    await this.directDebitService.cancel_direct_debit_trace(body);
    this.btn_restart_conciliation = false;
    this.response_trace_conciliation = null;
    await this.updateCurrentDirectDebit();
  };

  restart_process = async() => {
    this.btn_restart = false;
    this.loading_section['UPLOADED'] = true;
    let body = {
      id: this.current_direct_debit._id,
      user: this.sessionUser.uid,
    };
    await this.directDebitService.cancel_direct_debit_afiliacion(body);
    this.loading_section['UPLOADED'] = false;
    await this.updateCurrentDirectDebit();
  };

  getHeader = (obj: any, type: any) => {
    let response: any = [];
    switch(type) {
      case "BDV":
        return obj.filter((row: any) => row.TIPOREG === '01');
      case "MERCANTIL":
        return obj.filter((row: any) => row.TIPOREG === '1');
    }
    
  };

  formatElements = (elements: any) => {
    let response: any = [];
    let element: any;
    elements.map((ele: any) => {
      element = {
        res: ele.TIPOREG,
        success: ele.ERROR ? false : true,
        identity: ele.CEDULA,
        num_cuenta: ele.NROCTA1,
        reference: ele.REFDEB,
        amount: ele.TOTCOB,
        description: ele.DESCSTA,
        num_credit: ele.REFDEB.replace(/^0+/, '').slice(0, -1),
        error: ele.ERROR ? ele.ERROR : undefined,
      };
      response = [...response, element];
    });
    return response;
  };

  downloadFile = async (url:any, extension: string, name: string) => {
    let res = await this.directDebitService.downloadImage(url, extension);
    this.downloadCheck();
    if(res) {
      saveAs(res, `${name}.${extension}`);
    }
  }

  finalizar = async() => {
    let body = {
      id: this.current_direct_debit._id
    };
    await this.directDebitService.finish_direct_debit_alliances(body);
    this.updateCurrentDirectDebit();
  }

}
