<ng-template #content let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                                Bancos
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</ng-template>


<div class="mt-md-3 p-3">

    <div class="row ">
        <div class="col-12 col-md-8 offset-md-2 text-end pt-2" >
            <button class="btn btn-outline-primary" routerLink="/console/direct-debit-afiliations-history">
                Histórico
            </button>   
        </div>
    </div>
        <div class="row ">
        <div class=" col-12 col-md-8 offset-md-2">

            <h5 class="mb-1 text-secondary  mb-4 text-center"> Afiliaciones
            </h5>
            <!-- <a href="javascript:void()" (click)="conciliar_manual()">Conciliar Manul</a> -->
            <!-- {{ pending.resp[0] | json }} <br>
            {{ date | json }} -->
            <ng-container *ngIf="pending?.resp?.length>0">
                <!-- {{ date }} {{ current_date }} -->
            
            </ng-container>
            <ng-container >
                <!-- <div class="col-12 text-end pt-2">
                    <a href="javascript:void()" [routerLink]="['/console/direct-debit-history']">Histórico</a>
                </div> -->
                <div class="col-12 text-end ">
                    <form autocomplete="nope" novalidate [formGroup]="form" class="text-start">

                    <div class="row mt-2">
                        <div class="form-group  col-12  mt-4"   >
                            <div class="form-floating">
                                <select class="form-select" id="bank" formControlName="bank" aria-label="Floating label select example" (change)="updateCurrentDirectDebit()">
                                        <option value="">Selecione una opción</option>
                                        <option *ngFor="let item of banks; let i=index" [value]="item?.code">
                                            {{ item.name }}
                                        </option>
                                </select>
                                <label for="origin_account">Entidad Bancaria</label>
                            </div>           
                        </div>

                        <div class="form-group  col-12  mt-4"   >
                            <div class="form-floating">
                                <select class="form-select" id="type" formControlName="type" aria-label="Floating label select example" (change)="updateCurrentDirectDebit()">
                                        <option *ngFor="let item of types; let i=index" [value]="item?.id">
                                            {{ item.name }}
                                        </option>
                                </select>
                                <label for="origin_account">Tipo</label>
                            </div>           
                        </div>


                    </div>

                    </form>
                    <!-- <button type="button" class="btn-close" aria-label="Close" (click)=" view > 1 ? view = 1 : offcanvas.dismiss('Cross click'); "></button> -->
                </div>

                <div class=" mt-4 list-group list-group-radio d-grid gap-2 border-0 w-auto">

                    <div  *ngIf="current_direct_debit">

                        <div class="card box-shadow" *ngIf="current_direct_debit">

                            <div class="card-body" >
                                <div class="row">
                                    <div class="col-6">
                                        <h5 class="card-title">Lote </h5>
                                        <h6 class="card-subtitle mb-2 text-muted">{{ current_direct_debit.lote }}</h6>
                                        <!-- <h6 class="card-subtitle mb-2 pt-3 font-weight-bold" *ngIf="current_direct_debit.rate">Tasa: {{ current_direct_debit.rate }} Bs </h6> -->
                                    </div>
                                    <div class="col-6 ">
                                        <div class="float-end">
                                            <!-- <h5 class="card-title fs-amount text-end">{{ current_direct_debit.quantity }}</h5> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <small class="text-muted">Última actualización: {{ current_direct_debit.date | date: 'dd/MM/yyyy h:mm:ss a'}}</small>
                            </div>
                        </div>
                    </div>
                      <br *ngIf="current_direct_debit">
                    <!-- *ngFor="let item of section_transactions"  -->
                    <div *ngFor="let section of sections; let i = index" class="position-relative  box">
                        
                        <label class="list-group-item py-4 " for="listGroupRadioGrid1">
                            <ng-container *ngIf="!loading">

                                <!-- {{ profiles | json }}
                                {{ profile | json }} -->
                                    <!-- <div class="inner-box">1 -->
                                <div class="row">
                                    <div class="col-md-1 col-2">
                                    <div id="circle" class="completed circle-enabled " [class.completed]="status_sections[section.status.trim()]!=='PENDING'">
                                        {{ (i+1) }}
                                            <i class="fas fa-check verification-icon"></i>
                                    </div>
                                    <!-- {{ status_sections[section.status.trim()] | json }} -->
                                </div>

                                <div class="col-md-11 col-10">
                                    <span class="pl-5 font-weight-bold">{{ section.name }}</span>
                                    <div class="text-secondary small " *ngIf="status_sections[section.status]!=='PENDING'">{{ status_sections[section.status]?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>
                                    <div class="small " *ngIf="status_sections[section.status].user"><i class="fa fa-user text-secondary" aria-hidden="true"></i>  {{ status_sections[section.status]?.user?.name + ' ' + status_sections[section.status]?.user?.lastName }}</div>


                                    <div class="float-end" *ngIf="section.status==='INITIAL' && status_sections[section.status]==='PENDING' && _auth.IsPermitid(['OWNER']) && !current_direct_debit "><!-- && profile['key']==='OWNER'-->
                                        <button type="button" class="btn btn-primary btn-sm" (click)="startDirectDebit()"><i class="fas fa-circle-notch fa-spin" *ngIf="loading_section[section.status] && !current_direct_debit "></i> Iniciar</button>

                                    </div>
                                    <div class="pt-3 w-100" *ngIf="section.status==='INITIAL' && current_direct_debit">
                                        <app-direct-debit-trace-document [setType]="'DIRECTDEBIT_AFILIATION_GENERATE_START_'+current_direct_debit.trace" (trace)="updateCurrentDirectDebit()" (response)="setResponse($event, 'START')" *ngIf="startTraceDocument"></app-direct-debit-trace-document>
                                        <div class="float-end pt-1" *ngIf="btn_restart">
                                            <button type="button" class="btn btn-danger btn-sm  " (click)="restart_process()"><i class="fas fa-circle-notch fa-spin" *ngIf="loading_section[section.status] "></i> Reintentar </button>&nbsp;
                                        </div>
                                    </div>

                                    <span class="w-100" *ngIf="section.status==='GENERATED' && status_sections[section.status]!=='PENDING'">
                                        
                                        <div class="files-list">
                                            <div class="single-file active-cursor" title="Descargar" >
                                                <img src="assets/public/images/ic-file.svg" width="45px" alt="file">
                                                <div class="info">
                                                <h4 class="name small">
                                                    <a href="javascript:void()" (click)="downloadFile(status_sections[section.status]?.url, 'txt', current_direct_debit.lote)" class="active-cursor" title="Descargar Archivo de Domiciliación">Descargar Archivo Conciliación</a>
                                                </h4>
                                                <p class="size">
                                                    {{ '' }}
                                                </p>
                                                
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </span>

                                    
                                    <span *ngIf="section.status==='UPLOADED'">
                                        <div class="float-end" *ngIf="section.status==='UPLOADED' && status_sections[section.status]==='PENDING' && _auth.IsPermitid(['OWNER']) && status_sections['DOWNLOADED']!=='PENDING'"><!-- && profile['key']==='OWNER'-->
                                            <button type="button" class="btn btn-danger btn-sm pr-4" (click)="cancel_process()"><i class="fas fa-circle-notch fa-spin" *ngIf="loading_section[section.status] "></i> Cancelar</button>&nbsp;
                                            <button type="button" class="btn btn-primary btn-sm" (click)="confirm_upload()"><i class="fas fa-circle-notch fa-spin" *ngIf="loading_section[section.status] "></i> Confirmar</button>
                                        </div>
                                    </span>



                                    <span *ngIf="section.status==='PROCESING'">
                                        <ng-container *ngIf="status_sections['UPLOADED']!=='PENDING' && status_sections['UPLOADED']!=='' && _auth.IsPermitid(['FINANCIAL', 'OWNER']) "><!-- && profile['key']==='ADMIN'-->
                                            <div class="w-100 pb-4" *ngIf="!response_trace_conciliation">
                                                <br>
                                                <app-afiliacion-dnd (response)="getResponse($event)" (reset)="reset($event)" [config]="config_read" [direct_debit]="current_direct_debit" *ngIf="status_sections['PROCESING']==='PENDING' && current_direct_debit"></app-afiliacion-dnd>
                                                <span class="float-end" *ngIf="show_conciliacion_button"><button class="btn btn-primary btn-sm" [class.disabled]="loading_section[section.status]" (click)="conciliar()"><i class="fas fa-circle-notch fa-spin" *ngIf="loading_section[section.status]"></i> Conciliar</button></span>

                                            </div>

                                            <div class="pt-3 w-100" >
                                                <app-direct-debit-trace-document [setType]="'DIRECTDEBIT_GENERATE_AFILIATIONS_'+current_direct_debit.trace" (trace)="updateCurrentDirectDebit()" (response)="setResponse($event, 'CONCILIATION')"  *ngIf="startTraceDocumentDomiciliation"></app-direct-debit-trace-document>
                                                
                                                <div class="float-end pt-1" *ngIf="btn_restart_conciliation">
                                                    <button type="button" class="btn btn-danger btn-sm  " (click)="restart_process_conciliation()"><i class="fas fa-circle-notch fa-spin" *ngIf="loading_section[section.status] "></i> Reintentar </button>&nbsp;
                                                </div>
                                            </div>


                                        </ng-container>


                                        
                                        <div class="w-100" *ngIf="status_sections['PROCESING']!=='PENDING'">
                                        <div class="files-list" >
                                            <div class="single-file active-cursor" title="Descargar" >
                                                <img src="assets/public/images/ic-file.svg" width="45px" alt="file">
                                                <div class="info">
                                                  <h4 class="name small">
                                                    <a href="javascript:void()" (click)="downloadFile(status_sections[section.status]?.url, 'txt', 'afiliacion_respuestabanco_'+current_direct_debit.lote)"  class="active-cursor" title="Descargar Archivo de Domiciliación">Descargar Respuesta Banco</a>

                                                  </h4>
                                                  <p class="size">
                                                    {{ '' }}
                                                  </p>
                                                  
                                                </div>
                                                
                                            </div>
                                        </div>
                                        </div>
                                        
                                        


                                    </span>

                                    <span *ngIf="section.status==='FINISHED'">
                                       
                                        
                                        <div class="w-100" *ngIf="status_sections['FINISHED']!=='PENDING'">
                                        <div class="files-list" >
                                            <div class="single-file active-cursor" title="Descargar" >
                                                <img src="assets/public/images/ic-file.svg" width="45px" alt="file">
                                                <div class="info">
                                                  <h4 class="name small">
                                                    <a href="javascript:void()" (click)="downloadFile(status_sections[section.status]?.url, 'xlsx', 'afiliacion_resultado_'+current_direct_debit.lote)" class="active-cursor" title="Descargar Archivo de Domiciliación Corrida">Descargar Respuesta Conciliación</a>
                                                  </h4>
                                                  <p class="size">
                                                    {{ '' }}
                                                  </p>
                                                </div>
                                            </div>

                                            <div class="single-file active-cursor" title="Descargar" *ngIf="status_sections[section.status]?.url_failed">
                                                <img src="assets/public/images/ic-file.svg" width="45px" alt="file">
                                                <div class="info">
                                                  <h4 class="name small">
                                                    <a href="javascript:void()" (click)="downloadFile(status_sections[section.status]?.url_failed, 'xlsx', 'afiliacion_resultado_general_'+current_direct_debit.lote)" class="active-cursor" title="Descargar Archivo de Domiciliación Resumen">Descargar Respuesta Conciliación (General)</a>
                                                  </h4>
                                                  <p class="size">
                                                    {{ '' }}
                                                  </p>
                                                </div>
                                            </div>

                                        </div>
                                        <span class="float-end" ><button class="btn btn-primary btn-sm" (click)="finalizar()" >Finalizar</button></span>

                                    </div>
                                    </span>
</div>
                                </div>

                                
                                   
                            </ng-container>
                            <ng-container *ngIf="loading">
                                <div class="col-12 text-center">
                                    Cargando...
                                </div>
                            </ng-container>
                         </label>
                         
                    </div>

                   

                   


                </div>


            </ng-container>         

            <ng-container *ngIf="loading">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>

           

        </div>
    </div>

</div>