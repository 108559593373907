import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login-form/login.component';
import { LogoutComponent } from './components/auth/logout/logout.component';
import { RegistroComponent } from './components/auth/registro/registro.component';
import { IdentGuard } from './guards/ident.guard';
import { IsAuthGuard} from './guards/isAuth.guard';
import { NoAuthGuard } from './guards/NoAuth.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ChatComponent } from './components/chat/chat.component';
import { SearchClientComponent } from './components/search-client/search-client.component';
import { ConsultaPsicometricoComponent } from './components/consulta-psicometrico/consulta-psicometrico.component';
import { ListOnlineComponent } from './components/list-online/list-online.component';
import { profile } from 'console';
import { ProfilesComponent } from './components/profiles/profiles.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { ContactProfileComponent } from './components/contact-profile/contact-profile.component';
import { ConsultQuoteComponent } from './components/consult-quote/consult-quote.component';
import { CreditOptionComponent } from './components/credit-option/credit-option.component';
import { CreditCalculatorComponent } from './components/credit-calculator/credit-calculator.component';
import { CreditSaveComponent } from './components/credit-save/credit-save.component';
import { ConsultQuotaComponent } from './components/consult-quota/consult-quota.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { ApproveConditionsComponent } from './components/approve-conditions/approve-conditions.component';
import { ContactOnboardingComponent } from './components/contact-onboarding/contact-onboarding.component';
import { PostComponent } from './components/post/post.component';
import { ContactFinancialPaymentsComponent } from './components/contact-financial-payments/contact-financial-payments.component';
import { PersonPaymentsComponent } from './components/person-payments/person-payments.component';
import { WebcamComponent } from 'ngx-webcam';
import { BiometriaComponent } from './components/biometria/biometria.component';
import { FinancialPaymentsComponent } from './components/financial-payments/financial-payments.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { StadisticComponent } from './components/stadistic/stadistic.component';
import { InstallmentsConsoleComponent } from './components/installments-console/installments-console.component';
import { ReportComponent } from './components/report/report.component';
import { ContactsApprovatedComponent } from './components/contacts-approvated/contacts-approvated.component';
import { ApproveConditinsViewComponent } from './components/approve-conditins-view/approve-conditins-view.component';
import { ExportComponent } from './components/export/export.component';
import { CreateContactComponent } from './components/create-contact/create-contact.component';
import { DashboardInviceComponent } from './components/dashboard-invice/dashboard-invice.component';
import { UsersComponent } from './components/users/users.component';
import { FinancalDashboardComponent } from './components/financal-dashboard/financal-dashboard.component';
import { StadisticDashboardComponent } from './components/stadistic-dashboard/stadistic-dashboard.component';
import { StadisticEjecutivesComponent } from './components/stadistic-ejecutives/stadistic-ejecutives.component';
import { IsProfileGuard } from './guards/isProfile.guard';
import { DashboardDirecDebitComponent } from './components/dashboard-direc-debit/dashboard-direc-debit.component';
import { DashboardDirectDebitAffiliationComponent } from './components/dashboard-direct-debit-affiliation/dashboard-direct-debit-affiliation.component';

import { DirecDebitHistoryComponent } from './components/direc-debit-history/direc-debit-history.component';
import { DirecDebitAlliancesHistoryComponent } from './components/direc-debit-alliances-history/direc-debit-alliances-history.component';
import { DirecDebitAfiliationsHistoryComponent } from './components/direc-debit-afiliations-history/direc-debit-afiliations-history.component';

import { NotificationXlsxComponent } from './components/notification-xlsx/notification-xlsx.component';
import { RatesComponent } from './components/rates/rates.component';
import { PlansComponent } from './components/plans/plans.component';
import { CargaMasivaClientComponent } from './components/carga-masiva-client/carga-masiva-client.component';

import { DashboardDirecDebitAlliancesComponent } from './components/dashboard-direc-debit-alliances/dashboard-direc-debit-alliances.component';
import { ClientPlansComponent } from './components/person-plans/person-plans.component';

const routes: Routes = [
  /*{
    path: 'console',
    loadChildren: () => import('./modules/la/la.module').then(m => m.LaModule),
    canActivate: [AuthGuard]

  },*/
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'register',
    component: RegistroComponent,
    canActivate: [IsAuthGuard, IsProfileGuard],
    data: {
      profiles: ['OWNER', 'ANALIST'],
    },
  },
  {
    path: 'console/financial-payments',
    component: FinancialPaymentsComponent,
    canActivate: [IsAuthGuard, IsProfileGuard],
    data: {
      profiles: ['OWNER', 'ANALIST'],
    },
  },

  {
    path: 'console/financial-payments/:shop',
    component: FinancialPaymentsComponent,
    canActivate: [IsAuthGuard, IsProfileGuard],
    data: {
      profiles: ['OWNER', 'ANALIST'],
    },
  },

  {
    path: 'console/payments',
    component: PaymentsComponent,
    canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 
  },
  { path: 'redirect',
     component: DashboardComponent,
     canActivate: [IsAuthGuard],
  },

{
  path: 'console/constacts',
  component: ContactsComponent,
  canActivate: [IsAuthGuard],


},

{
  path: 'console/users',
  component: UsersComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 

},



{
  path: 'console/create-constacts',
  component: CreateContactComponent,
  canActivate: [IsAuthGuard]
},


{
  path: 'console/constacts-approved',
  component: ContactsApprovatedComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 

},

{
  path: 'console/search-client',
  component: SearchClientComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 

},
{
  path: 'console/psicometrico',
  component: ConsultaPsicometricoComponent,
  canActivate: [IsAuthGuard]
},
{
  path: 'console/person/profile/:code',
  component: ContactProfileComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},
{
  path: 'console/person/onboarding/:code',
  component: ContactOnboardingComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},
{
  path: 'console/person/financial-payments/:code',
  component: ContactFinancialPaymentsComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},
{
  path: 'console/online',
  component: ListOnlineComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 

},

{ path: 'console/credit-option',
component: CreditOptionComponent,
canActivate: [IsAuthGuard,IsProfileGuard],
data: { 
  profiles: ['OWNER','ANALIST']
} 

},

{ path: 'console/consult-quota',
  component: ConsultQuotaComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 

},

{ path: 'console/credit-calculator',
component: CreditCalculatorComponent,
canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 

},

{ path: 'console/credit-save',
  component: CreditSaveComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER']
  } 

},


{ path: 'console/invoice-detail/:code',
  component: InvoiceComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 

},

{ path: 'client-quota',
component: ConsultQuoteComponent
},

{ path: 'verification/approve-conditions/:code',
  component: ApproveConditionsComponent
},

{ path: 'console/payment/:invoice/:installment',
  component: PostComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},

{ path: 'console/payments/person/:code',
  component: PersonPaymentsComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},

{ path: 'console/post',
  component: PostComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},

{ path: 'console/post/person/:code_person',
  component: PostComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},

{ path: 'console/stadistic',
  component: StadisticComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},

{ path: 'console/export',
  component: ExportComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},
{ path: 'console/installments-console',
  component: InstallmentsConsoleComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},


{ path: 'console/stadistic_approved',
  component: ReportComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 
},


{ path: 'console/invoice-dashboard',
  component: DashboardInviceComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 
},



{ path: 'console/dashboard-direct-debit',
  component: DashboardDirecDebitComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER','FINANCIAL']
  } 
},

{ path: 'console/dashboard-direct-debit-alliances',
  component: DashboardDirecDebitAlliancesComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER','FINANCIAL']
  } 
},


{ path: 'console/dashboard-direct-debit-affiliation',
  component: DashboardDirectDebitAffiliationComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER','FINANCIAL']
  } 
},



{ path: 'console/direct-debit-history',
  component: DirecDebitHistoryComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER','FINANCIAL']
  } 
},

{ path: 'console/direct-debit-afiliations-history',
  component: DirecDebitAfiliationsHistoryComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER','FINANCIAL']
  } 
},



{ path: 'console/direct-debit-alliances-history',
  component: DirecDebitAlliancesHistoryComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER','FINANCIAL']
  } 
},



{ path: 'console/finacial-dashboard',
  component: FinancalDashboardComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER','ANALIST','FINANCIAL']
  } 
},


{ path: 'console/estadisticas',
  component: StadisticDashboardComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 
},


{ path: 'console/notificacion-xlsx',
  component: NotificationXlsxComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER']
  } 
},



{ path: 'console/carga-masiva-cleinte-xlsx',
  component: CargaMasivaClientComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER']
  } 
},

{ path: 'console/estadisticas-ejecutivos',
  component: StadisticEjecutivesComponent,
  canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 
},
{ 
  path: 'console/rates',
component: RatesComponent,
canActivate: [IsAuthGuard,IsProfileGuard],
data: { 
  profiles: ['OWNER']
} },

{
  path: 'console/plans',
  component: PlansComponent,
  canActivate: [IsAuthGuard],
  data: {
    profiles: ['OWNER'],
  },
},

{
  path: 'console/person/plans/:code',
  component: ClientPlansComponent,
  canActivate: [IsAuthGuard],
  data: {
    profiles: ['OWNER', 'ANALIST'],
  },
},

  {
    path: '**',
    redirectTo: 'login',
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
