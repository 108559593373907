

import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { Subscription } from 'rxjs';
import * as alertifyjs from 'alertifyjs';


@Component({
  selector: 'app-contacts-approvated',
  templateUrl: './contacts-approvated.component.html',
  styleUrls: ['./contacts-approvated.component.css']
})
export class ContactsApprovatedComponent implements OnInit {

  form:any;
  people:any = [];
  loading:any;
  usersActives:any = {};
  socketServiceSub:any;
  submit_disabled:any;
  active_filter:any;

   reload_loading:any;
   rows_number = 0;
   rows_size = 10;
   reload_loading_pluss =true;
   rows:any = [];
   rows_temp:any = [];

   @Output() changeView = new EventEmitter<any>();


   search_form: any = '';
   constructor(
    private offcanvasService: NgbOffcanvas,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user:UserService,
    private sanitizer: DomSanitizer,
    public socketService: SocketioService,


  ) { }
  ngOnDestroy(): void {

    this.socketServiceSub.unsubscribe();
    
  }

  ngOnInit(): void {
    this.getPeople();

    this.socketServiceSub = this.socketService.usersActives.subscribe((resp: any) => {
      // this.usersActives = resp.filter((item:any) => item.uid != this.uid);
  
      this.usersActives = {};
 
       resp?.map((item:any)=>{
 
       if(item?.role?.role ==  "CLIENT_ROLE"){
 
         this.usersActives[item?.person?.code] = true;
 
       };
 
 
       return item;
 
      });

      this.usersActives;
     });
  }

  openBottom(content: TemplateRef<any>,data:any= {}) {

    this.offcanvasService.open(content, { position: 'bottom',keyboard: false} );

  }

  closeAddClient(){
    this.getPeople();
    this.offcanvasService.dismiss("");
    
  }


  getPeople = async () =>  {
    this.loading = true;

    const resp =  await this._user.getPeople_approved();
    
  
    this.people = resp.resp;
    this.nextRows();

    this.loading = false;

  }


  nextRows = () =>{

    this.reload_loading_pluss =true;

this.rows_number = this.rows_number + this.rows_size;

if(this.rows_number > this.people?.lenght){
 this.rows_number = this.people?.lenght;
}

let rows = this.people?.slice(0, this.rows_number);
this.rows = rows;

this.reload_loading_pluss = false;
  
  }

  
  getIdentity(item:any){

    if(!(item?.identitys?.length > 0))
      return "";

      return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;
  }



sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}
onScrollDown() {
  if(!this.active_filter){

    this.nextRows();

   }

  console.log("scrolled down!!");
}

onScrollUp() {
  
  console.log("scrolled up!!");
}


  onScroll() {

    console.log("scrolled!!");
  }

  search_form_method() {

if(this.search_form?.trim() != ''){

  this.active_filter = true;

      this.rows_temp = this.rows_temp?.length > 0? this.rows_temp : this.rows;

      let datas:any = [];
      
       datas =  this.people?.filter((element:any) =>{

        let name =  element?.person?.name;
        let lastName =  element?.person?.lastName;
        let ename =  element?.person?.ejecutive?.name;
        let elastName =  element?.person?.ejecutive?.lastName;
        let identity = this.getIdentity(element)

        let status='';
        
      if(!(element?.status_approved) ){

         status='Sin consultar'

      }else if(element?.status_approved?.status_approved  == 'APPROVED' ){

        status='Aprobado'
        
      }else if(element?.status_approved?.status_approved  == 'REFUSED' ){

        status='Rechazado'

        
      }else if(element?.status_approved?.status_approved  == 'WAIT_PSICOMETRICO' ){

        status='Psicometrico'

        
      }
      
        let resp = 
        identity.toLowerCase().includes(this.search_form) ||
         name.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1 ||
         status.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1 ||
         name.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1 ;

        //  ename.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1 ||
        //  elastName.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1;
         

        return resp;
      });
      



      this.rows= datas;


      }else{
             

            this.rows = this.rows_temp?.length > 0? this.rows_temp : this.rows;
            this.active_filter = false;

      }

   }

gotoWhatsapp(num:any) : void {
  window.open(" https://wa.me/"+num?.replace('+', ''), "_blank");
}


externalProfile(code:any){

  let url = `console/person/profile/${code}`;
  window.open(url, '_blank');
}


invitarCompra = async () => {
    
  try {

          let result = await this._user.invitarCompraAll({});
          alertifyjs.success(`Invitaciones enviadas exitosamente`);

            if (!(result?.error)) {
  
             }
  
  } catch (error) {
    console.error(error);
     alertifyjs.error(`Error al enviar invitaciones`);

    
  }

}


}

