

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, lastValueFrom } from "rxjs";
import { environment } from 'src/environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";
import { SocketioService } from '../socket/socketio.service';
const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})


export class AuthService {

  public auth$: BehaviorSubject<any> = new BehaviorSubject(false);
  public ident$: BehaviorSubject<any> = new BehaviorSubject(false);
  public menuOption$: BehaviorSubject<any> = new BehaviorSubject(1);

  
  private url_la: any;
  env: any = environment;
  private auth: any;
  private tokenName:any;
  constructor(
    public httpClient: HttpClient,
    public socketService: SocketioService

  ) {

    this.url_la = this.env.BASE_LA;
    this.tokenName = "x-token";
    this.main();
    

  }

  setIdent(ident:Boolean){
   localStorage.setItem('ident',ident.toString());
    this.ident$.next(ident);
  }


  setmenuOption(menuOption:any){
    localStorage.setItem('menuOption',menuOption);
     this.menuOption$.next(menuOption);
   }


  get menuOption(){

    if(localStorage.getItem('menuOption') !== this.menuOption$.getValue()){
      this.menuOption$.next(localStorage.getItem('menuOption'));
    }

    return this.menuOption$;
    
   }
  

  get ident(){

    if(localStorage.getItem('ident') !== this.ident$.getValue()?.toString()){
      this.ident$.next(localStorage.getItem('ident'));
    }
    return this.ident$.getValue();
   }

  main = () => {

    if (localStorage.getItem(this.tokenName)) {
     const token = localStorage.getItem(this.tokenName);
     this.setAuth({token});

    }
  }


  login = async ({ username, password }: any) => {

    const headers = { 'content-type': 'application/json' }
    const request = {};
    const body = { username, password,role:"USER_ROLE" };
    try {

      return lastValueFrom(this.httpClient.post<any>(`${this.baseUrl}/api/auth/login`, body, { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
  }


  reloadConsult = async () => {


    try {

      const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');
      
      const request = {};

     return  lastValueFrom( this.httpClient.get<any>(this.baseUrl + '/api/auth/vesions', { headers: headers, params: request }));

    
  } catch (error) {

    console.error(error);

  }
}



getPersonTypeIdentity = async () => {

  const headers = { 'content-type': 'application/json' }
  const request = {};
 
  try {

    return  lastValueFrom( this.httpClient.get<any>(this.baseUrl + '/api/person/type_identity', { headers: headers, params: request }));

  } catch (error) {

    console.error(error);

  }
}



  refresh_token = async (body: any) => {

    const headers = { 'content-type': 'application/json' }
    const request = {};
    try {

      return lastValueFrom(this.httpClient.post<any>(`${this.baseUrl}/api/auth/refresh-token`, body, { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
  }



  get baseUrl() {

    return this.url_la;
  }

  get sessionUser() {

    const token: any = localStorage.getItem('x-token');
    const decodedToken = helper.decodeToken(token);
    return decodedToken?.user

  }



  get isAuth() {

    return this.auth;
  }

  setAuth = (res: any) => {
    const { token } = res;

    if (token) {
      this.setToken(token);
    }


  }

  setToken = (token: any) => {
    localStorage.setItem(this.tokenName, token);

   // const expirationDate = helper.getTokenExpirationDate(token);
    //const isExpired = helper.isTokenExpired(token);

    this.auth = true;
    this.auth$.next(true);

    if(!this.socketService?.socket?.connected){
      this.socketService.setupSocketConnection(token);

    }

  }

  logout = () => {

    this.auth = false;
    localStorage.clear();

    this.socketService.disconnect();

    this.auth$.next(false);
    this.setIdent(false);
    this.setmenuOption(1);

  }



  IsPermitid = (profiles:any = []) => {

    let sessionUser:any =  this.sessionUser?.profile || []; 

    const hasAllElems = sessionUser.some((elem:any) => profiles.includes(elem));
  
    if (hasAllElems) {
        return true;
    } else {
        return false;
    }
  
  }

  getProfiles = () =>{

    return {
      OWNER : 'Owner',
      ANALIST : 'Analista',
      EJECUTIVE : 'Promotor',
      ADMIN : 'Administrador',
    }
  }
  
getVersion = async () => {
  const url = 'assets/version.json';
  
  const timestamp = new Date().getTime();
  const noCacheUrl = `${url}?cache=${timestamp}`;
  
  
  
    const headers = new Headers();
    headers.append('Cache-Control', 'no-cache');
    headers.append('Pragma', 'no-cache');
    
    const requestOptions = {
      method: 'GET',
      headers: headers,
  };
  
    return new Promise((resolve, reject) => {
      fetch(noCacheUrl, requestOptions)
          .then(response => {
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              return response.json();
          })
          .then(data => {
              resolve(data);
          })
          .catch(error => {
              reject(error);
          });
  });
  }
  
}
