<ng-template #content let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                                Contacto
                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12 col-md-6 offset-md-3">

                    <app-search-client (close)="closeAddClient()"></app-search-client>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="container">

    <div class="mt-md-3 p-3">

        <div class="row ">
            <div class=" col-12 col-md-6 offset-md-3  p-0 ">
                <h5 class="mb-4 text-secondary mb-5 text-center">
                    <i class="  fas fa-users"></i> Contactos

                </h5>

                <div class="form-group col-12 mt-5 mb-3 ">
                    <div class="mt-2 mb-4">
                        <button (click)="invitarCompra()" class="btn btn-outline-primary">Invitar clientes a comprar</button>
                    </div>


                    <div class="input-group ">

                        <div class="form-floating ">
                            <input (keyup)="search_form_method()" type="text" [(ngModel)]="search_form" class="form-control " id="contact" placeholder="name@example.com">
                            <label for="contact" class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span >Cédula, Nombe, Apellido
                            </span>
                        </label>
                        </div>
                        <!-- <button (click)="search_form_method()" [disabled]=" submit_disabled" class="btn btn-primary">
                        Buscar
                        <span *ngIf="submit_disabled">
                        <i class="fa  fa-spinner fa-spin"></i>
                        </span> 
                        </button>-->
                    </div>

                </div>

                <div *ngIf="people && !loading" class="mt-3 pt-4 my-3 p-3 bg-body rounded shadow-sm">


                    <h6 class="border-bottom pb-2 mb-0">Nombre
                        <div class="float-end text-secondary"> {{rows.length}} de {{people?.length}}</div>
                    </h6>

                    <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScrollDown()" (scrolledUp)="onScrollUp()">

                        <ng-container>
                            <div *ngFor="let item of rows ; let i=index" class="d-flex text-muted pt-4">
                                <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <div class="float-end text-end">
                                        <span *ngIf="!(item?.status_approved)" class="text-secondary">Sin consultar </span>
                                        <span *ngIf="item?.status_approved?.status_approved == 'APPROVED'" class="text-success">Aprobado </span>
                                        <span *ngIf="item?.status_approved?.status_approved == 'REFUSED'" class="text-danger">Rechazado </span>
                                        <span *ngIf="item?.status_approved?.status_approved == 'WAIT_PSICOMETRICO'" class="text-warning">Psicometrico </span>

                                    </div>

                                    <div class="d-flex justify-content-between">
                                        <div (click)="externalProfile(item?.person?.code)" class="cursor-pounter">
                                            <span class="text-dark fw-bold text-capitalize">{{item?.person?.name}} {{item?.person?.lastName}}</span>

                                        </div>



                                        <!-- <a [routerLink]="['/console/person/profile/',item?.person?.code]">Ir</a> -->

                                    </div>
                                    <div class="mt-1 mb-2">
                                        <span class="d-block text-dark "> {{getIdentity(item)}}</span>

                                    </div>
                                    <ng-container *ngFor="let item of item?.contacts; let i=index">
                                        <div *ngIf="item?.type_contact.code =='PHONE_CELL'" (click)="gotoWhatsapp(item?.contact)" class="mt-2 text-primary cursor-pounter">{{item?.contact}}</div>
                                        <div *ngIf="item?.type_contact.code =='EMAIL'" class="mt-1 small text-dark ">{{item?.contact}}</div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>

                    </div>

                    <!-- 
                <small class="d-block text-end mt-3">
                              <a href="#">All suggestions</a>
                            </small> -->
                </div>


                <ng-container *ngIf="loading ">
                    <div class="w-100 text-center mt-2">
                        <img src="./assets/public/images/loading.gif  " alt=" ">
                    </div>
                </ng-container>

            </div>
        </div>
    </div>
</div>