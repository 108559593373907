import { Component, OnDestroy, OnInit } from '@angular/core';
import { WebcamImage } from 'ngx-webcam';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-biometria',
  templateUrl: './biometria.component.html',
  styleUrls: ['./biometria.component.css']
})

export class BiometriaComponent implements OnInit,OnDestroy {
  biometria:any;
  take = true;
  constructor(   
     private _user:UserService
    ) { }

  webcamImage: WebcamImage | undefined;
  loading:any;
  handleImage =  async (webcamImage: WebcamImage) =>{

    this.webcamImage = webcamImage;
  }

  submit =  async () =>{
this.loading = true;
  let  img_b64:any = this.webcamImage?.imageAsDataUrl;
  let  png = img_b64.split(',')[1];
  let  binary = this.fixBinary(window.atob(png));// <-- Usamos la fn "fixBinary"
  let  the_file = new Blob([binary], {type: 'image/png'});// <-- Sacamos el encode
  let  imagen_firma = new File([the_file], 'imagen_firma.png', { type: 'image/png' });

  try {

    const formData = new FormData();
    
    formData.append("params", JSON.stringify({
     type: this.biometria?.type,
     code_person: this.biometria?.person,
   }));

    if (imagen_firma) {
      formData.append("archivo", imagen_firma);
  
     }
  
    let resp = await this._user.setPersonBiometria(formData);
    this.loading = false;

    this.close();

  } catch (error) {
    this.loading = false;

    console.log(error);
    
    
  }
  }


  ngOnInit(): void {

    this._user.biometria$.subscribe((response) => {

         this.biometria = response;
 
      });
 
  
    this._user.setNav(false);

  }
  
  ngOnDestroy(): void {
    this._user.setNav(true);

  }

  changeTake(){
    this._user.setTake(true);
    
  }

  close(){
    this._user.setBiometria(null);

  }

   fixBinary (bin:any) {
    let  length = bin.length;
    let  buf = new ArrayBuffer(length);
    let  arr = new Uint8Array(buf);
    for (let  i = 0; i < length; i++) {
      arr[i] = bin.charCodeAt(i);
    }
    return buf;
  }
  

}