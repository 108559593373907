<ng-template #content let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                                Contacto
                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12 col-md-6 offset-md-3">

                    <app-search-client (close)="closeAddClient()"></app-search-client>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="container">

    <div class="mt-md-3 p-3">

        <div class="row ">
            <div class=" col-12 col-md-6 offset-md-3  p-0 ">
                <h5 class="mb-4 text-secondary mb-5 text-center">
                    <i class="  fas fa-users"></i> Contactos
                </h5>

                <!-- <div class="mt-4 mb-5 text-center w-100">
                        <img class="img-style base_img" [src]="sanitize('../assets/public/images/banner.png')">

                    </div> -->

                <div class="mt-5 ">

                    <div class="row">
                        <div class="col-12 mt-4">
                            <div class="card" *ngIf="_auth.IsPermitid(['OWNER','ANALIST','EJECUTIVE'])" (click)="openBottom(content,{})">
                                <div class="card-body">
                                    <h5 class="card-title">Buscar

                                        <i class="float-end pt-2  fas fa-search"></i>

                                    </h5>
                                </div>
                            </div>
                        </div>

                        <div class="col-12  mt-4">
                            <div class="card" [routerLink]="['/console/constacts']">
                                <div class="card-body">
                                    <h5 class="card-title">Contactos
                                        <i class="float-end pt-2  fas fa-users"></i>

                                    </h5>

                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    </div>
</div>