<div class="b-w">
    <div class="header-b h5">
        <span class="fas fa-arrow-left text-secondary float-start ms-3 mt-1" (click)="close()"></span>

        <span *ngIf="biometria?.type == 'SELFIE'">Tomate una Selfi</span>
        <span *ngIf="biometria?.type == 'PHOTO_IDENTITY'">Documento de identidad (Cedula)</span>
    </div>

    <div class="mt-5">
        <div class="b-bg">
            <div class=" bio-circle-person " *ngIf="!webcamImage && biometria?.type == 'SELFIE'"></div>

            <img *ngIf="webcamImage" style="width: 100%; border-radius: 5px; " [src]="webcamImage.imageAsDataUrl" />

        </div>

        <app-webcam (getPicture)="handleImage($event)">
        </app-webcam>

    </div>

    <div class="booton-b pb-3 pt-3">
        <div class="container-fluid" *ngIf="biometria?.type == 'SELFIE'">
            <div class=" h5 fw-bold text-center">
                Tomale una foto <br> al cliente
            </div>
            <div class="mt-3 text-md-center">
                Coloca el rostro del cliente en el circulo, y tome la foto.
            </div>
        </div>

        <div class="container-fluid" *ngIf="biometria?.type == 'PHOTO_IDENTITY'">
            <div class=" h5 fw-bold text-center">
                Coloca el Tomale una foto a tu Documento de identidad (Cedula)
            </div>
            <div class="mt-3 text-md-center">
                Coloca el Documento de identidad (Cedula) del cliente en la camara, y tomale una foto.
            </div>
        </div>



        <div class="btn-b">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-4 offset-md-4">
                        <div class="d-grid gap-2 mt-3 text-center">
                            <button *ngIf="!webcamImage" class="btn btn-outline-primary me-2 btn-blo mt-2" (click)="changeTake()">Tomar foto</button>
                            <a *ngIf="webcamImage && !loading" class="text-secondary mt-2" (click)="webcamImage = undefined">Reset</a>
                            <button [disabled]="loading" (click)="submit()" *ngIf="webcamImage " class="btn btn-primary me-2 btn-blo mt-2">
                                <span *ngIf="!loading">Continuar</span>
                                <span *ngIf="loading">Guardando...</span>

                                </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>


</div>