import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})


export class CommonService {

  rate$: BehaviorSubject<any> = new BehaviorSubject(false);
  env: any = environment;

  constructor(
    public httpClient: HttpClient,

  ) { }



  get baseUrl() {

    return this.env.BASE_LA;;
  }




  get OnboardingUrl() {

    return this.env.ONBOARDING_LA;
  }


  getCountry = async (params: any) => {

    const headers = { 'content-type': 'application/json' }
    const request = params;
   
    try {

      return  lastValueFrom( this.httpClient.get<any>(this.baseUrl + '/api/person/country', { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
  }

  download(publicId: string, extension:string) {
    try{
    return lastValueFrom(
      this.httpClient.get(`${this.baseUrl}/api/invoice-credit/download?public_id=${publicId}&ext=${extension}`, { responseType: 'blob' })
      );
    } catch (error) {
      console.error(error);
      return null;
    }
  }



  getState =  async (params: any) => {

    const headers = { 'content-type': 'application/json' }
    const request = params;
   
    try {

      return  lastValueFrom( this.httpClient.get<any>(this.baseUrl + '/api/person/state', { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
  }


  getTypeAddress = async (params: any) => {

    const headers = { 'content-type': 'application/json' }
    const request = params;
   
    try {

      return  lastValueFrom( this.httpClient.get<any>(this.baseUrl + '/api/person/address_type', { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
  }



  getCity =  async (params: any) => {

    const headers = { 'content-type': 'application/json' }
    const request = params;
   
    try {

      return  lastValueFrom( this.httpClient.get<any>(this.baseUrl + '/api/person/city', { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
  }


  setAddress_Person = async (body: any) => {
    const headers = { 'content-type': 'application/json' }
    const request = {};
    try {

      return lastValueFrom(this.httpClient.post<any>(`${this.baseUrl}/api/person/address_Person`, body, { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
  }


/*
  setPayment_method = async (body: any) => {
    const headers = {}
    const request = {};
    try {
      return lastValueFrom(this.httpClient.post<any>(`${this.baseUrl}/api/payment_method/customer`, body, { headers: headers, params: request }));
    } catch (error) {
      console.error(error);
    }
  }*/
    


  setPayment_method = async (body: any) => {
    const headers = {}
    const request = {};
    try {
      return lastValueFrom(this.httpClient.post<any>(`${this.OnboardingUrl}/api/payment_method/payment_method`, body, { headers: headers, params: request }));
    } catch (error) {
      console.error(error);
    }
  }



  getPayment_method =  async (params: any) => {

    const headers = { 'content-type': 'application/json' }
    const request = params;
   
    try {

      return  lastValueFrom( this.httpClient.get<any>(this.baseUrl + '/api/payment_method/customer', { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
  }

  delete_payment_method = async (body: any) => {
    const headers = { 'content-type': 'application/json' }
    const request = {};
    try {

      return lastValueFrom(this.httpClient.request<any>('delete', `${this.OnboardingUrl}/api/payment_method/payment_method`, { body: body, headers: headers, params: request}));


    } catch (error) {

      console.error(error);

    }
  }

/*
  delete_payment_method = async (body: any) => {
    const headers = { 'content-type': 'application/json' }
    const request = {};
    try {

      return lastValueFrom(this.httpClient.post<any>(`${this.baseUrl}/api/payment_method/deleteCustomer`, body, {headers: headers, params: request  }));

    } catch (error) {

      console.error(error);

    }
  }*/

  delete_address_person = async (body: any) => {
    const headers = { 'content-type': 'application/json' }
    const request = {};
    try {

      return lastValueFrom(this.httpClient.post<any>(`${this.baseUrl}/api/person/delete_address_person`, body, { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
  }



  working_condition = async (body: any) => {
    const headers = { 'content-type': 'application/json' }
    const request = {};
    try {

      return lastValueFrom(this.httpClient.post<any>(`${this.baseUrl}/api/person/working_condition`, body, { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
  }


  

  income = async (body: any) => {
    const headers = { 'content-type': 'application/json' }
    const request = {};
    try {

      return lastValueFrom(this.httpClient.post<any>(`${this.baseUrl}/api/person/income`, body, { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
  }


  


  getPerson_Address =  async (params: any) => {

    const headers = { 'content-type': 'application/json' }
    const request = params;
   
    try {

      return  lastValueFrom( this.httpClient.get<any>(this.baseUrl + '/api/person/address_Person', { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
  }
  

  getcustomer =  async (params: any) => {

    const headers = { 'content-type': 'application/json' }
    const request = params;
   
    try {

      return  lastValueFrom( this.httpClient.get<any>(this.baseUrl + '/api/person/person_customer', { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
  }



  getScore_buro_stadistic = async (params: any={}) => {

    const headers = { 'content-type': 'application/json' }
    const request = params;
   
    try {

      return  lastValueFrom( this.httpClient.get<any>(this.baseUrl + '/api/buro/score_buro_stadistic', { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
  }

  change_domiciliation = async (body: any) => {
    const headers = { 'content-type': 'application/json' }
    const request = {};
    try {
      return lastValueFrom(this.httpClient.put<any>(`${this.baseUrl}/api/payment_method/direct_debit_customer_default`, body, {headers: headers, params: request  }));
    } catch (error) {

      console.error(error);

    }
  }

  reset_afiliation_domiciliation = async (body: any) => {
    const headers = { 'content-type': 'application/json' }
    const request = {};
    try {
      return lastValueFrom(this.httpClient.put<any>(`${this.baseUrl}/api/payment_method/reset_afiliation`, body, {headers: headers, params: request  }));
    } catch (error) {

      console.error(error);

    }
  }

  delete_afiliation_domiciliation = async (body: any) => {
    const headers = { 'content-type': 'application/json' }
    const request = {};
    try {
      return lastValueFrom(this.httpClient.put<any>(`${this.baseUrl}/api/payment_method/delete_afiliation`, body, {headers: headers, params: request  }));
    } catch (error) {

      console.error(error);

    }
  }


}
